import React, {  } from 'react';

const ForecastBox = ({ period, onClick }) => {
    return (
        <div className="forecast-box" onClick={() => onClick(period)}>
            <div className="forecast-box-header">
                <div className="forecast-name">{period.name}</div>
                <div className="forecast-temperature">
                    {period.temperature}&deg;{period.temperatureUnit}
                </div>
            </div>
            <div className="forecast-icon">
                <img src={period.icon} alt={period.shortForecast} />
            </div>
            <div className="forecast-details">
                <div className="forecast-description">{period.shortForecast}.</div>
                <div className="forecast-wind">
                    Wind from {period.windDirection} at {period.windSpeed}
                </div>
            </div>
        </div>
    );
};

export default ForecastBox;