import React, { useState } from 'react';

const DetailedForecastModal = ({ periods, initialIndex, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % periods.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? periods.length - 1 : prevIndex - 1
        );
    };

    const handleOverlayClick = (event) => {
        // Close the modal only if the click is on the overlay, not on the content
        if (event.target.classList.contains('detailed-forecast-overlay')) {
            onClose();
        }
    };

    const currentPeriod = periods[currentIndex];

    return (
        <div className="detailed-forecast-overlay" onClick={handleOverlayClick} >
            
            <div className="detailed-forecast-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <button className="prev-button" onClick={handlePrev}>
                    &#8249;
                </button>
                <div className="forecast-details">
                    <h2>{currentPeriod.name}</h2>
                    <img src={currentPeriod.icon} alt={currentPeriod.shortForecast} />
                    <p>
                        <strong>Temperature:</strong> {currentPeriod.temperature}&deg;
                        {currentPeriod.temperatureUnit}
                    </p>
                    <p>
                        <strong>Forecast:</strong> {currentPeriod.detailedForecast}
                    </p>
                    <p>
                        <strong>Wind:</strong> Wind from {currentPeriod.windDirection} at{" "}
                        {currentPeriod.windSpeed}
                    </p>
                </div>
                <button className="next-button" onClick={handleNext}>
                    &#8250;
                </button>
            </div>
        </div>
    );
};

export default DetailedForecastModal;