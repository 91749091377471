import React, { useState } from 'react';
import ForecastBox from './ForecastBox';
import DetailedForecastModal from './DetailedForecastModal';

const SideScrollingForecast = ({ periods }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    return (
        <div className="side-scrolling-forecast-container">
            <div className="side-scrolling-forecast">
                {periods.map((period, index) => (
                    <ForecastBox
                        key={period.number}
                        period={period}
                        onClick={() => setSelectedIndex(index)}
                    />
                ))}
            </div>
            {selectedIndex !== null && (
                <DetailedForecastModal
                    periods={periods}
                    initialIndex={selectedIndex}
                    onClose={() => setSelectedIndex(null)}
                />
            )}
        </div>
    );
};

export default SideScrollingForecast;