import React, { useState, useMemo } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Box, FormGroup, FormControlLabel, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import RadarImageComponent from './RadarImageComponent';
import regionBoundaries from './regionBoundaries';

const theme = createTheme({
  palette: {
    primary: { main: '#4caf50' },
    secondary: { main: '#ff9100' },
  },
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': { color: '#fff' },
  '& .MuiSwitch-switchBase.Mui-checked': { color: '#4caf50' },
  '& .MuiSwitch-track': { backgroundColor: '#bfbfbf', opacity: 0.5 },
  '& .MuiSwitch-track.Mui-checked': { backgroundColor: '#4caf50' },
}));

const getRegionFromCoords = (latitude, longitude) => {
  for (const regionName in regionBoundaries) {
    const { latMin, latMax, lonMin, lonMax } = regionBoundaries[regionName];
    if (latitude >= latMin && latitude <= latMax && longitude >= lonMin && longitude <= lonMax) {
      return regionName;
    }
  }
  return "Continental US";
};

const getRegionURL = (coords) => {
  
  const regionName = getRegionFromCoords(coords.latitude, coords.longitude);
  console.log("regionName",regionName)
  return `https://radar.weather.gov/ridge/standard/${regionBoundaries[regionName].urlName}_loop.gif?v=${Math.random()}`;
};

export default function RadarControlComponent({ coords, pointsData }) {
  const [radarSource, setRadarSource] = useState('local');
  const imageUrl = useMemo(() => {
    if (radarSource === 'regional' && coords) {
      return getRegionURL(coords);
    } else if (radarSource === 'local' && pointsData?.properties?.radarStation) {
      return `https://radar.weather.gov/ridge/standard/${pointsData.properties.radarStation}_loop.gif?v=${Math.random()}`;
    }
    return '';
  }, [coords, pointsData, radarSource]);

  const handleChange = (event) => {
    setRadarSource(event.target.checked ? 'regional' : 'local');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <RadarImageComponent imageUrl={imageUrl} />
        <FormGroup row display="flex" justifyContent="center" alignItems="center" flexDirection="row">
          <Typography variant="subtitle1" style={{ marginRight: 6, display: 'flex', alignItems: 'center' }}>
            Local
          </Typography>
          <FormControlLabel
            control={<CustomSwitch checked={radarSource === 'regional'} onChange={handleChange} />}
            label=""
            style={{ margin: 0, justifyContent: 'center', display: 'flex' }}
          />
          <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center' }}>
            Regional
          </Typography>
        </FormGroup>
      </Box>
    </ThemeProvider>
  );
}