import React, { useEffect, useState } from 'react';

export default function RadarImageComponent({ imageUrl }) {
  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);

  useEffect(() => {
    setCurrentImageUrl(imageUrl);
    const interval = setInterval(() => {
      setCurrentImageUrl(`${imageUrl}?v=${Math.random()}`);
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [imageUrl]);

  return (
    <div>
      {currentImageUrl ? <img className="radar-gif" alt="radar gif" src={currentImageUrl}  /> : <p>Loading radar image...</p> }
    </div>
  );
}